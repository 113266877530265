/**
 * Utility function for tools, products and statuses filtering. It contains the "NeuroSales" === "Emotions" logic
 */
const filterToolsAndProducts = (tool, product) => {
  if (!tool || !product) return false;
  const toolName = tool.name || tool.toolName;
  return product.name === toolName || (toolName === 'NeuroSales' && product.name === 'Emotions');
};

export default filterToolsAndProducts;
