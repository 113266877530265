import { gql } from '@apollo/client';

const FEATURE_DATA_TO_RETRIEVE = `
    id
    name
    type
    price
    floor
    notes
    code
    meters
    width
    length
    visible
    available
    createdOn
    status
`;

export const GET_FEATURES = gql`
  query getFeatures($project_id: ID!, $lean: Boolean) {
    getFeatures(project_id: $project_id, lean: $lean) {
      ${FEATURE_DATA_TO_RETRIEVE}
    }
  }
`;

export const GET_FEATURES_OPTIONS = (project_id, lean) => {
  return {
    variables: {
      project_id,
      lean,
    },
    fetchPolicy: 'no-cache',
  };
};
