import React from 'react';
import { observer } from 'mobx-react';

const Icons = observer(() => {
    const icons = [
        {
          name: 'arrow-down',
          icon: 'arrow-down.svg',
        },
      ];
    
      const containers = ['home-configurator'];
    
      return (
        <style>
          {/* Generic icons */}
          {icons.reduce((res, i) => {
            const containerClasses = containers.map((c) => `.${c} .icon.${i.name}`);
            const url = `url('${process.env.REACT_APP_BUCKET_BASEURL}/FloorPlanning/img/icon/${i.icon}');`;
            let css;
            if (!i.noMask)
              css = `
                ${containerClasses.join(',')} {
                  background-color: ${i.color ? i.color : 'black'};
                  -webkit-mask-image: ${url}
                  mask: ${url}
                  -webkit-mask-repeat: no-repeat;
                  -webkit-mask-position: center;
                  ${i.customCSS ? i.customCSS : ''}
                }
              `;
            else
              css = `
                ${containerClasses.join(',')} {
                  background-image: url('${process.env.REACT_APP_BUCKET_BASEURL}/HomeConfigurator/img/icon/${i.icon}');
                  ${i.customCSS ? i.customCSS : ''}
                }
              `;
            return res.concat(css);
          }, [])}
        </style>
    );
});

export default Icons;