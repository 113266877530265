import * as iso2iso3_data from '../translations/iso2iso3.json';

export const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
/**
 * computes €/mq of an appartment
 * @param {number} price
 */
export const calculateInstallment = (price, taxRate, durationInYear, percentage, round) => {
  const i = taxRate / 100 / 12;
  const v = Math.pow(1 + i, durationInYear * 12);
  const t = (i * v) / (v - 1);
  const rate = round ? Math.round(t * price * percentage) : t * price * percentage;

  return rate ? rate : 0;
};

/**
 *
 * @param {*} array
 * @param {*} name
 * @param {*} order  // acs or desc
 */
export const sortArray = (array, name, order) => {
  return array.sort(function (a, b) {
    return order === 'desc' ? b[name] - a[name] : a[name] - b[name];
  });
};

/**
 *
 * @param {*} array
 * @param {*} name
 * @param {*} order  // acs or desc
 */
export const sortArrayString = (array, name, order) => {
  return order === 'asc' ? array.sort((a, b) => (a[name] > b[name] ? 1 : b[name] > a[name] ? -1 : 0)) : array.sort((a, b) => (a[name] < b[name] ? 1 : b[name] < a[name] ? -1 : 0));
};

/**
 *
 * @param {*} config configuration object
 * @param {*} order order number
 * @param {*} prefix link prefix
 */
export const getLink = (paths, order, prefix = '') => {
  let finalPaths = [];
  for (var i = 4; i < paths.length; i++) {
    finalPaths.push(paths[i]);
  }

  let link = '';
  if (order === 1) return '/';
  for (let i = 1; i <= finalPaths.length; i += 1) {
    link += `/${finalPaths[i - 1]}`;
    if (i === order - 1) break;
  }
  return prefix + link;
};

/**
 *
 * @param {*} order order number
 * @param {*} prefix link prefix
 */

export const getLinkByParams = (order, params, actualNav) => {
  /*let path = '';

  let actualNavSplitted = actualNav.split('/:');
  for (var i = 1; i < order; i++) {
    path += '/' + actualNavSplitted[i];
  }
console.log("path init: ", path);
  path = path.replace('model', params.model);
  path = path.replace('typology', params.typology);
  path = path.replace('dimension', params.dimension);
  path = path.replace('planimetry', params.planimetry);
  path = path.replace('dimension', params.dimension);
  path = path.replace('building', params.building);
  path = path.replace('appartment', params.appartment);
  path = path.replace('side', params.side);
  path = path.replace('formula', params.formula);
  console.log("path final: ", path);
  return path;*/

  let path = actualNav;

  path = path.replace(':model', params.model);
  path = path.replace(':typology', params.typology);
  path = path.replace(':dimension', params.dimension);
  path = path.replace(':planimetry', params.planimetry);
  path = path.replace(':dimension', params.dimension);
  path = path.replace(':building', params.building);
  path = path.replace(':appartment', params.appartment);
  path = path.replace(':side', params.side);
  path = path.replace(':formula', params.formula);
  path = path.replace(':das', params.das);

  return path;
};


/**
 *
 * @param {*} paramList the list of params
 * @param {*} breadCrumb the breadCrumb web or desk version
 * @param {*} type the target type
 */
export const getParamFromType = (paramList, breadCrumb, type) => {
  for (let i = 0; i < breadCrumb.length; i++) if (breadCrumb[i].type === type) return paramList[i];
};

/**
 *
 * @param {*} uriString the url Param
 */
export const replaceWithDash = (uriString) => uriString.split(' ').join('-');
export const encodeUrlString = (uriString) => encodeURI(uriString);
/**
 *
 * @param {*} uriString the url Param
 */
//export const replaceWithSpace = (uriString) => uriString.split('-').join(' ');
export const decodeUrlString = (uriString) => decodeURI(uriString);
/**
 *
 * @param {*} percent the percentage of total
 * @param {*} total the total
 */
export function percentage(percent, total) {
  return percent ? ((percent / 100) * total).toFixed(2) : 0;
}

export function roundToFirstDecimal(value) {
  //return Math.round(value * 10) / 10;
  return value;
}

export function roundToNextHundred(valueParam, round = false) {
  let value = valueParam;
  if (value === '' || value === null) {
    value = '0';
  }

  if (round) {
    return Math.round(parseFloat(parseFloat(value).toFixed(4)) / 1) * 1;
  } else return Math.ceil(parseFloat(parseFloat(value).toFixed(4)) / 1) * 1;
}

export function percent(value, total) {
  return ((value * 100) / total).toFixed(6);
}

export function removeDoubleSlash(string) {
  return string.replace('//', '/');
}

/**
 *
 * @param {*} navigation navigation
 * @param {*} element element of navigation
 */
export function getElementFromNavigation(navigation, element) {
  let navigationElement;
  switch (element) {
    case 'model':
      navigationElement = navigation.match.params.model;
      break;
    case 'typology':
      navigationElement = navigation.match.params.typology;
      break;
    case 'formula':
      navigationElement = navigation.match.params.formula;
      break;
    case 'planimetry':
      navigationElement = navigation.match.params.planimetry;
      break;
    case 'building':
      navigationElement = navigation.match.params.building;
      break;
    case 'appartment':
      navigationElement = navigation.match.params.appartment;
      break;
    default:
  }

  return navigationElement;
}

/**
 *
 */
export function getPathname(baseUrl) {
  if (baseUrl && baseUrl.length > 0 && baseUrl.charAt(baseUrl.length - 1) !== '/') {
    baseUrl = `${baseUrl}/`;
  }

  let path = window.location.pathname.replace(baseUrl, '');
  path = path.replace('/neurosales', '');

  if (path === '/') {
    path = '';
  }

  if (path && path.length > 0 && path.charAt(path.length - 1) === '/') {
    path = path.slice(0, -1);
  }

  return path;
}

/**
 *
 * @param {*} percent the percentage of total
 * @param {*} total the total
 */
export function calculatePriceCapitolato(capitolatoName, typologyCode, modelCode, capitolatiPriceMap) {
  let priceCapitolato;
  capitolatiPriceMap.forEach((capitolato) => {
    if (capitolato.name === capitolatoName) {
      capitolato.price.forEach((price) => {
        if ((!price.typology || price.typology === typologyCode) && (!price.model || price.model === modelCode)) {
          priceCapitolato = price.value;
        }
      });
    }
  });

  return priceCapitolato;
}

/**
 *
 * @param {*} plan the planimetry
 * @param {*} field the field of plan document
 */
export function getSuperficie(entity, field, withDecimal) {
  let superficie = entity[field];
  if (typeof superficie !== 'undefined' && superficie !== null && typeof superficie === 'string') {
    superficie = superficie.replace(',', '.');
  }

  return superficie ? (withDecimal ? parseFloat(superficie) : parseInt(Math.round(superficie))) : 0;
}

export function getPlanInfo(plan, field, elementNameSeparator, elementNameIndex, sides) {
  let affacci = [];
  if (field === 'affaccio') {
    if (sides) {
      sides.forEach((s) => {
        if (s.name && elementNameSeparator && elementNameIndex !== undefined && s.name.split(elementNameSeparator).length > elementNameIndex) {
          affacci.push(capitalize(s.name.split(elementNameSeparator)[elementNameIndex].toLowerCase()));
        } else {
          affacci.push(capitalize(s.name.toLowerCase()));
        }
      });
    } else {
      plan.entityAppartments &&
        plan.entityAppartments.forEach((ap) => {
          ap.sides &&
            ap.sides.forEach((s) => {
              if (s.name && elementNameSeparator && elementNameIndex !== undefined && s.name.split(elementNameSeparator).length > elementNameIndex) {
                affacci.push(capitalize(s.name.split(elementNameSeparator)[elementNameIndex].toLowerCase()));
              } else {
                affacci.push(capitalize(s.name.toLowerCase()));
              }
            });
        });
    }
    affacci = affacci.filter((value, index, self) => self.indexOf(value) === index);
  }

  const infoMap = {
    lotto: plan.name.charAt(0),
    edificio: plan.name.charAt(0) + plan.name.charAt(1),
    affaccio: affacci.join(', '),
  };

  return infoMap[field];
}

/**
 *
 * @param {*} name the name of the parameter
 */
export function getQSParameterValue(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const removeKey = (object, keys) =>
  keys.reduce((o, k) => {
    const { [k]: _, ...p } = o;
    return p;
  }, object);

export const capitalize = (string) => {
  return string.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};
/**
 * Returns a random number between min (inclusive) and max (inclusive)
 */
export const getRandom = (min, max) => {
  return Math.random() * (max - min + 1) + min;
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export const getRandomInt = (min, max) => {
  return Math.floor(getRandom(Math.ceil(min), Math.floor(max)));
};

export const removeParamFromUrl = (key, sourceURL) => {
  var rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    params_arr = queryString.split('&');
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + '?' + params_arr.join('&');
  }
  return rtn;
};
export const checkFileExist = (urlToFile) => {
  var xhr = new XMLHttpRequest();
  xhr.open('HEAD', urlToFile, false);

  xhr.send();

  return xhr.status !== 404;
};

export const getNavValueFromAppartment = (appartment, nav) => {
  if (appartment === null || typeof appartment === undefined || nav === null || typeof nav === undefined || nav === '') return null;

  let value = '';
  switch (nav) {
    case 'planimetry':
      if (appartment.plan && appartment.plan.name) value = appartment['plan'].name;
      break;
    case 'das':
      if (appartment.plan && appartment.plan.quadrants && appartment.plan.quadrants[0]) value = appartment.plan.quadrants[0];
      break;
    case 'model':
      if (appartment.plan && appartment.plan.model && appartment.plan.model.name) value = appartment.plan.model.name;
      break;
    case 'typology':
      if (appartment.plan && appartment.plan.typology && appartment.plan.typology.name) value = appartment.plan.typology.name;
      break;
    case 'dimension':
      if (appartment.plan && appartment.plan.dimension && appartment.plan.dimension.name) value = appartment.plan.dimension.name;
      break;
    case 'building':
      if (appartment.sides && appartment.sides[0] && appartment.sides[0].building && appartment.sides[0].building.name) value = appartment.sides[0].building.name;
      break;
    case 'side':
      if (appartment.sides && appartment.sides[0] && appartment.sides[0].name) value = appartment.sides[0].name;
      break;
    case 'appartment':
      if (appartment.name) value = appartment.name;
      break;
    // case 'formula':
    // break;
    default:
      break;
  }
  return value;
};

export const getFollowUpToSummaryPath = (appartment, flow) => {
  const navigation = flow.filter((step) => step.component === 'Summary')[0].navigation;
  const navSplitted = navigation.split('/:'); //split garantisce l'ordine delle occorrenze della stringa originale
  let navValues = null;
  navSplitted.forEach((navKey) => {
    const item = flow.filter((step) => step.navigation.includes(navKey))[0];
    if (item) {
      let navValue = getNavValueFromAppartment(appartment, navKey);
      if (navValue && navValue !== '') {
        const elementNameSeparator = item.componentParams.elementNameSeparator;
        const elementNameIndex = item.componentParams.elementNameIndex;
        if (elementNameSeparator && elementNameIndex && navValue.split(elementNameSeparator).length > elementNameIndex && navValue.split(elementNameSeparator)[elementNameIndex]) {
          navValue = navValue.split(elementNameSeparator)[elementNameIndex];
        }
        navValues = { ...navValues, [navKey]: navValue };
      }
    }
  });
  return getLinkByParams(-1, navValues, navigation);
};

export const isUnauthorizedVendor = (user) => {
  return user.role !== 'admin' && user.role !== 'vendor' && user.role !== 'vendor_manager' && user.id !== 'fake-client';
};

export const iso2toiso3 = (iso2) => {
  return iso2iso3_data.default.find((el) => el.iso2.localeCompare(iso2) === 0)?.iso3.toLowerCase();
};

/**
 * Taken from https://github.com/axios/axios/blob/main/lib/helpers/combineURLs.js
 * Creates a new URL by combining the specified URLs
 *
 * @param {string} baseURL The base URL
 * @param {string} relativeURL The relative URL
 * @returns {string} The combined URL
 */
export function combineURLs(baseURL, relativeURL) {
  return relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL;
}

export default {
  calculateInstallment,
  sortArray,
  sortArrayString,
  getLink,
  replaceWithDash,
  encodeUrlString,
  decodeUrlString,
  formatNumber,
  percentage,
  roundToFirstDecimal,
  roundToNextHundred,
  percent,
  getElementFromNavigation,
  calculatePriceCapitolato,
  getSuperficie,
  getQSParameterValue,
  removeDoubleSlash,
  removeKey,
  capitalize,
  getRandom,
  getRandomInt,
  removeParamFromUrl,
  checkFileExist,
  getNavValueFromAppartment,
  getFollowUpToSummaryPath,
  isUnauthorizedVendor,
  iso2toiso3,
};
