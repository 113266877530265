import { gql } from '@apollo/client';

export const USER_DATA_TO_RETRIEVE = `
    id
    firstName
    lastName
    email
    client
`;

export const USER_DATA_TO_RETRIEVE_BY_JWT = `
      id
      firstName
      lastName
      email
      project_ids
      language
      role
      hasGoogleToken
      vendorColor
      locale
`;

export const GET_ALL_USERS = gql`
    query getAllUsers($project_id :ID!) {
      getAllUsers(project_id: $project_id) {
        ${USER_DATA_TO_RETRIEVE}
        }
    }
`;

export const GET_USER_BY_CLIENT_ID = gql`
    query getUserByClientId($project_id :ID!, $clientId: ID!) {
      getUserByClientId(project_id: $project_id, clientId: $clientId) {
        ${USER_DATA_TO_RETRIEVE}
        }
    }
`;

export const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE_BY_JWT}
    }
  }
`;

export const ACTIVATE_MYHOME_MUTATION = gql`
  mutation createClientUser($project_id :ID!,$userInput: UserInput!, $myhome_version: String!) {
    createClientUser(project_id: $project_id,  input: $userInput, myhome_version: $myhome_version) {
      ${USER_DATA_TO_RETRIEVE}
        }
      }
`;

export const GET_ALL_VENDORS_PROFILE = gql`
  query getVendorsEmail($project_id: ID!) {
    getVendorsEmail(project_id: $project_id)
  }
`;

export const GET_USER_BY_CLIENT_ID_OPTIONS = (project_id, clientId) => {
  return {
    variables: {
      project_id,
      clientId,
    },
    fetchPolicy: 'no-cache',
  };
};

export const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
    fetchPolicy: 'network-only',
  };
};

export const ACTIVATE_MYHOME_OPTIONS = (project_id, userInput, myhome_version) => {
  return {
    variables: {
      project_id,
      userInput,
      myhome_version,
    },
    fetchPolicy: 'no-cache',
  };
};

export const GET_CLIENT_DEFAULT_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};

export const GET_ALL_VENDORS_PROFILE_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id,
    },
    fetchPolicy: 'no-cache',
  };
};

export const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

export const UPDATE_USER_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};
