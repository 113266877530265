import { gql } from '@apollo/client';

const PLANS_DATA_TO_RETRIEVE = `
    id
    name
    count
    image
    desk_visibility
    web_visibility
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    SuperficieLoggia
    SuperficieAttico
    SuperficieBalcone
    count
    minPrice
    floors
    model {
    id
    name
    }
    typology{
    id
    name
    }
    quadrants
    boosted
`;

export const UPDATE_PLAN_VISITS_MUTATION = gql`
  mutation updatePlansVisits($project_id :ID!, $planId: ID!) {
    updatePlansVisits(project_id: $project_id, id: $planId) {
        ${PLANS_DATA_TO_RETRIEVE}
        }
      }
`;

export const SEND_PLAN = gql`
  mutation sendPlan($project_id: ID!, $planName: String!, $client_id: ID!, $path:  String!) {
    sendPlan(project_id: $project_id, planName: $planName, client_id: $client_id, path: $path) 
  }
`;

export const SEND_DRAW_PLAN = gql`
  mutation sendDrawPlan($project_id: ID!, $planName: String!, $client_id: ID!, $base64: String!) {
    sendDrawPlan(project_id: $project_id, planName: $planName, client_id: $client_id, base64: $base64) 
  }
`;

export const UPDATE_PLAN_VISITS_OPTIONS = (project_id, planId) => {
  return {
    variables: {
      project_id,
      planId,
    },
    fetchPolicy: 'no-cache',
  };
};

export const SEND_PLAN_OPTIONS = (project_id, planName, client_id, path) => {
  return {
    variables: {
      project_id,
      planName,
      client_id,
      path,
    },
    fetchPolicy: 'no-cache',
  };
};

export const SEND_DRAW_PLAN_OPTIONS = (project_id, planName, client_id, base64) => {
  return {
    variables: {
      project_id,
      planName,
      client_id,
      base64,
    },
    fetchPolicy: 'no-cache',
  };
};