import { gql } from '@apollo/client';
import { STANDARD_QUOTE_DATA_TO_RETRIEVE } from './Quote';

const APPARTMENT_DATA_TO_RETRIEVE = `
    name
    id
    order
    floor
    price
    discounted_price
    available
    active
    code
    enabled_web
    enabled_desk
    extraInfo
    superfici
    status
    availableQuadrants
    temporaryReserveExpiryOn
    movements {
      id
      status
      createdOn
    }
    plan {
      name
      boosted
      id
      model {
        id
        name
        visits
        desk_visibility
        web_visibility
      }
      typology {
        id
        name
        visits
        rooms
        desk_visibility
        web_visibility
        formulas {
          id
          increase
          name: label
          shortDescription
          description: longDescription
        }
      }
      dimension {
        id
        name
        desk_visibility
        web_visibility
      }
      web_visibility
      desk_visibility
      SuperficieGiardino
      SuperficieTotale
      SuperficieTerrazzo
      SuperficieAppartamento
      SuperficieLoggia
      SuperficieAttico
      SuperficieBalcone
      extraInfo
      quadrants
      visits
      visitsByDay{
        day
        visits
      }
      image
    }
    sides {
      id
      name
      building {
        id
        name
        floors
      }
    }
    ${STANDARD_QUOTE_DATA_TO_RETRIEVE}
`;

export const GET_ALL_APPARTMENTS = gql`
  query getAllAppartmentsPublic($project_id :ID!, $lean: Boolean) {
    getAllAppartmentsPublic(project_id: $project_id, lean: $lean) {
      ${APPARTMENT_DATA_TO_RETRIEVE}
    }
}`;

export const GET_APPARTMENT = gql`
  query getAppartment($project_id: ID!, $appartmentId: ID!) {
    getAppartment(project_id: $project_id, id: $appartmentId) {
      ${APPARTMENT_DATA_TO_RETRIEVE}
    }
}`;

export const GET_APPARTMENTS_FILTERED = gql`
  query getAppartmentsFiltered($project_id :ID!, $appartmentInput: AppartmentFilterInput!) {
    getAppartmentsFiltered(project_id: $project_id, appartmentInput: $appartmentInput) {
      ${APPARTMENT_DATA_TO_RETRIEVE}
      }
  }
`;

export const UPDATE_APPARTMENT_VISITS_MUTATION = gql`
  mutation updateAppartmentsVisits($project_id :ID!, $appartmentId: ID!) {
    updateAppartmentsVisits(project_id: $project_id, id: $appartmentId) {
        ${APPARTMENT_DATA_TO_RETRIEVE}
        }
      }
`;

export const GET_APPARTMENTS_DEFAULT_OPTIONS = (pName, lean, skip = false) => {
  return {
    variables: {
      project_id: pName,
      lean: true,
    },
    fetchPolicy: 'network-only',
    skip: skip,
  };
};

export const GET_APPARTMENT_DEFAULT_OPTIONS = (projectId, appartmentId, skip = false) => {
  return {
    variables: {
      project_id: projectId,
      appartmentId: appartmentId,
    },
    fetchPolicy: 'network-only',
    skip: skip,
  };
};

export const GET_APPARTMENTS_FILTERED_DEFAULT_OPTIONS = (project_id, appartmentInput) => {
  return {
    variables: {
      project_id,
      appartmentInput,
    },
    fetchPolicy: 'network-only',
  };
};

export const UPDATE_APPARTMENT_VISITS_OPTIONS = (project_id, appartmentId) => {
  return {
    variables: {
      project_id,
      appartmentId,
    },
    fetchPolicy: 'no-cache',
  };
};
