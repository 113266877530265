import { gql } from '@apollo/client';

const TYPOLOGY_DATA_TO_RETRIEVE = `
    id
    name
    desk_visibility
    web_visibility
    temp_web_visibility
    temp_desk_visibility
    active
    count
    minPrice
    rooms
`;


export const UPDATE_TYPOLOGY_VISITS_MUTATION = gql`
mutation updateTypologiesVisits($project_id :ID!, $typologyId: ID!) {
  updateTypologiesVisits(project_id: $project_id, id: $typologyId) {
        ${TYPOLOGY_DATA_TO_RETRIEVE}
      }
    }
`;

export const UPDATE_TYPOLOGY_VISITS_OPTIONS = (project_id, typologyId) => {
  return {
    variables: {
      project_id,
      typologyId,
    },
    fetchPolicy: 'no-cache',
  };
};