import { gql } from '@apollo/client';

const DIMENSION_DATA_TO_RETRIEVE = `
    id
    name
    desk_visibility
    web_visibility
    temp_web_visibility
    temp_desk_visibility
    active
    count
`;

export const UPDATE_DIMENSIONS_VISITS_MUTATION = gql`
  mutation updateDimensionsVisits($project_id :ID!, $dimensionId: ID!) {
    updateDimensionsVisits(project_id: $project_id, id: $dimensionId) {
        ${DIMENSION_DATA_TO_RETRIEVE}
    }
      }
`;

export const UPDATE_DIMENSION_VISITS_OPTIONS = (project_id, dimensionId) => {
  return {
    variables: {
      project_id,
      dimensionId,
    },
    fetchPolicy: 'no-cache',
  };
};