import { gql } from '@apollo/client';
import { USER_DATA_TO_RETRIEVE_BY_JWT } from './User';

const REFRESH_TOKEN_DATA_TO_RETRIEVE = `
    tokenType
    accessToken
    refreshToken
    expiresIn
`;

const LOGIN_BY_PROJECT_DATA_TO_RETRIEVE = `
    token{
        ${REFRESH_TOKEN_DATA_TO_RETRIEVE}
    }
    user{
        ${USER_DATA_TO_RETRIEVE_BY_JWT}
    }
`;

export const REFRESH_TOKEN = gql`
    mutation refreshToken($input: RefreshTokenInput!) {
        refreshToken(input: $input) {
            ${REFRESH_TOKEN_DATA_TO_RETRIEVE}
        }
    }
`;

export const LOGIN_BY_PROJECT = gql`
    mutation loginByProject($input: LoginByProjectInput!) {
        loginByProject(input: $input) {
        ${LOGIN_BY_PROJECT_DATA_TO_RETRIEVE}
    }
    }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const REFRESH_TOKEN_DEFAULT_OPTIONS = (refreshToken, email) => {
  return {
    variables: {
      input: {
        refreshToken,
        email,
      },
    },
  };
};

export const LOGIN_BY_PROJECT_DEFAULT_OPTIONS = (email, password, projectId) => {
  return {
    variables: {
      input: {
        email: email,
        password: password,
        project_id: projectId,
      },
    },
  };
};
