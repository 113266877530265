import { gql } from '@apollo/client';

const FLOOR_DATA_TO_RETRIEVE = `
    id
    floor
    quadrants
    quadrantsObjects {
      code
      modules
      available
      order
    }
    building{
        id
        name
        floors
        }
    sides {
      id
      name
      building {
        id
        name
        floors
      }
    }
`;

export const GET_ALL_FLOORS = gql`
  query getAllFloors($project_id: ID!) {
    getAllFloors(project_id: $project_id) {
      ${FLOOR_DATA_TO_RETRIEVE}
    }            
  }
`;

export const GET_ALL_FLOORS_OPTIONS = (project_id, skip=false) => {
  return {
    variables: {
      project_id
    },
    fetchPolicy: 'no-cache',
    skip: skip
  };
};