/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
// import { useMutation } from '@apollo/client';
import { useStore } from '../store/storeUtils';
import Client from '../client/GraphQLEndpoints';
import CookiesUtils from './cookiesUtils';
import { DESK_MODE, WEB_MODE } from '../constants/flow';
import { isUnauthorizedVendor } from './utils';
import { getLanguage } from './languageUtils';

const JwtChecker = () => {
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [firstCheck, setFirstCheck] = useState(true);

  const [logoutMutation] = useMutation(Client.LOGOUT);

  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO, Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId));

  const [loadClientInfo, clientInfo] = useLazyQuery(Client.GET_CLIENT);

  function redirectToLogin(act = true) {
    if (act && location.pathname.indexOf('/login') === -1) history.push('/login');
  }

  function logoutVendor(redirect = true) {
    logoutClient(false);
    store.logOut();
    store.setLoginVendor(false);

    redirectToLogin(redirect);
  }

  function logoutClient(redirect) {
    CookiesUtils.clientLogout();
    store.setLoginClient(false);
    store.setShowRegisterForm(false);
    store.setShowRegisterButton(false);
    store.setClientName(null);
    store.setMode(WEB_MODE);
    redirectToLogin(redirect);
  }

  const handleUserLanguage = (userData) => {
    const userLang = userData.language;
    const userLocale = userData.locale;

    const language = getLanguage(userLang, userLocale);
    if (language) {
      store.languageChangeHandler(language);
    }
  };

  useEffect(() => {
    if (userInfo && !userInfo.loading && userInfo.called) {
      // Se ho le informazioni dell'utente valorizzate e sono un vendor autorizzato
      // proseguo provando a caricare le informazioni del cliente
      if (userInfo?.data?.getUserByJWT && !isUnauthorizedVendor(userInfo.data.getUserByJWT)) {
        store.setLoggedUser(userInfo.data.getUserByJWT);
        store.setLoginVendor(store.loggedUser?.id ? true : false);

        handleUserLanguage(userInfo.data.getUserByJWT);

        if (!CookiesUtils.getClientID()) {
          logoutClient(redirect);
          store.setCheckingJwt(false);
          store.setInitializing(false);
        } else {
          loadClientInfo(Client.GET_CLIENT_DEFAULT_OPTIONS(store.projectId, CookiesUtils.getClientID()));
        }
      } else if (userInfo?.error?.message.includes('404') && store.componentMode && CookiesUtils.getClientID() && sessionStorage.getItem('videocallMode')) {
        // Se la chiamata getUserByJWT va a buon fine ma non torna niente di valorizzato, potrei essere il client in videocall.
        // Controllo quindi se floorplanning è usato come componente, se è presente il clientId nei cookie e se siamo in videocallMode dallo sessionStorage
        store.setLoggedUser({ id: 'fake-client' });
        store.setLoginVendor(true);
        store.setLoginClient(true);
        store.setCheckingJwt(false);
        store.setInitializing(false);
      } else {
        logoutMutation().catch((err) => console.error(err));
        logoutVendor(redirect);
        store.setCheckingJwt(false);
        store.setInitializing(false);
      }
      if (firstCheck) {
        setFirstCheck(false);
      }
    }
  }, [userInfo, !userInfo.loading, userInfo.called, userInfo.data, userInfo.error]);

  useEffect(() => {
    if (clientInfo && !clientInfo.loading && clientInfo.called) {
      if (clientInfo.data) {
        store.setClientName(clientInfo.data.getClient.firstName + ' ' + clientInfo.data.getClient.lastName);
        store.setMode(DESK_MODE);

        if (!CookiesUtils.getClientData())
          CookiesUtils.setClientData({
            firstName: clientInfo.data.getClient.firstName,
            lastName: clientInfo.data.getClient.lastName,
            email: clientInfo.data.getClient.email,
            notificationLanguage: clientInfo.data.getClient.notificationLanguage,
          });

        if (clientInfo.data.getClient.user) {
          CookiesUtils.setMyHome(clientInfo.data.getClient.user);
        }

        if (!store.loginClient) store.setLoginClient(true);

        store.setCheckingJwt(false);
        store.setInitializing(false);

        if (location.pathname === '/login') history.push('/');
      } else if (clientInfo.error || clientInfo.errors) {
        store.setCheckingJwt(false);
        store.setInitializing(false);
        logoutClient(redirect);
      }
    }
  }, [clientInfo, !clientInfo.loading, clientInfo.called, clientInfo.data, clientInfo.error]);

  useEffect(() => {
    if (store.forcingLogout && !sessionStorage.videocallMode) {
      logoutVendor();
      store.setForcingLogout(false);
    }
  }, [store.forcingLogout]);

  function handleVisibilityChange() {
    if (!document.hidden) {
      setRedirect(store.mode === DESK_MODE);
      store.setCheckingJwt(true);

      loadUserInfo();
    }
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => window.removeEventListener('visibilitychange', handleVisibilityChange);
  });

  useEffect(() => {
    if (store.projectId) loadUserInfo();
  }, [store.projectId]);

  return <></>;
};

export default JwtChecker;
