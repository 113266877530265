import { gql } from '@apollo/client';

const MODEL_DATA_TO_RETRIEVE = `
    id
    name
    desk_visibility
    web_visibility
    temp_web_visibility
    temp_desk_visibility
    active
    count
`;

export const UPDATE_MODEL_VISITS_MUTATION = gql`
  mutation updateModelsVisits($project_id :ID!, $modelId: ID!) {
    updateModelsVisits(project_id: $project_id, id: $modelId) {
        ${MODEL_DATA_TO_RETRIEVE}
        }
      }
`;

export const UPDATE_MODEL_VISITS_OPTIONS = (project_id, modelId) => {
  return {
    variables: {
      project_id,
      modelId,
    },
    fetchPolicy: 'no-cache',
  };
};