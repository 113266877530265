import { gql } from '@apollo/client';

const PRODUCT_DATA_TO_RETRIEVE = `
    name
    label
    title
    default
    roles
`;
export const GET_ALL_PRODUCTS = gql`
query getAllProducts($project_id :ID!){
  getAllProducts(project_id :$project_id){
    ${PRODUCT_DATA_TO_RETRIEVE}
  }
}`;

export const GET_ALL_PRODUCTS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

export const GET_PROJECT_TOOL_STATUS = gql`
  query getProjectToolStatus($project_id: ID!) {
    getProjectToolStatus(id: $project_id) {
      status
      toolName
    }
  }
`;

export const GET_PROJECT_TOOL_STATUS_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};
