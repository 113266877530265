import { GET_PROJECT_INFO, GET_PROJECT_INFO_DEFAULT_OPTIONS } from './modules/Project';
import { PRINT_PDF_FROM_HTML, PRINT_PDF_FROM_HTML_DEFAULT_OPTIONS } from './modules/Pdf';
import {
  GET_ALL_APPARTMENTS,
  GET_APPARTMENT,
  GET_APPARTMENTS_FILTERED,
  UPDATE_APPARTMENT_VISITS_MUTATION,
  GET_APPARTMENTS_DEFAULT_OPTIONS,
  GET_APPARTMENT_DEFAULT_OPTIONS,
  GET_APPARTMENTS_FILTERED_DEFAULT_OPTIONS,
  UPDATE_APPARTMENT_VISITS_OPTIONS,
} from './modules/Appartment';
import { UPDATE_MODEL_VISITS_MUTATION, UPDATE_MODEL_VISITS_OPTIONS } from './modules/Model';
import { UPDATE_TYPOLOGY_VISITS_MUTATION, UPDATE_TYPOLOGY_VISITS_OPTIONS } from './modules/Typology';
import { UPDATE_PLAN_VISITS_MUTATION, UPDATE_PLAN_VISITS_OPTIONS, SEND_PLAN, SEND_PLAN_OPTIONS, SEND_DRAW_PLAN, SEND_DRAW_PLAN_OPTIONS } from './modules/Plan';
import {
  GET_QUOTE,
  GET_QUOTE_BY_CLIENT,
  CREATE_QUOTE_MUTATION,
  UPDATE_QUOTE_MUTATION,
  SEND_QUOTE_MUTATION,
  GET_QUOTE_OPTIONS,
  GET_QUOTE_BY_CLIENT_OPTIONS,
  CREATE_QUOTE_OPTIONS,
  UPDATE_QUOTE_OPTIONS,
} from './modules/Quote';
import {
  GET_DRAFT_QUOTE,
  GET_DRAFT_QUOTE_BY_CLIENT,
  CREATE_DRAFT_QUOTE_MUTATION,
  UPDATE_DRAFT_QUOTE_MUTATION,
  DELETE_DRAFT_QUOTE_MUTATION,
  GET_DRAFT_QUOTE_OPTIONS,
  GET_DRAFT_QUOTE_BY_CLIENT_OPTIONS,
  CREATE_DRAFT_QUOTE_OPTIONS,
  UPDATE_DRAFT_QUOTE_OPTIONS,
  DELETE_DRAFT_QUOTE_OPTIONS,
} from './modules/DraftQuote';
import { GET_ALL_POLICIES, GET_ALL_POLICIES_DEFAULT_OPTIONS } from './modules/Policy';
import {
  GET_ALL_USERS,
  GET_USER_BY_CLIENT_ID,
  GET_USER_INFO,
  ACTIVATE_MYHOME_MUTATION,
  GET_ALL_VENDORS_PROFILE,
  GET_USER_BY_CLIENT_ID_OPTIONS,
  ACTIVATE_MYHOME_OPTIONS,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_ALL_VENDORS_PROFILE_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
} from './modules/User';
import { CONTACT_MUTATION, CONTACT_OPTIONS } from './modules/Contact';
import { GET_FEATURES, GET_FEATURES_OPTIONS } from './modules/Feature';
import { REFRESH_TOKEN, REFRESH_TOKEN_DEFAULT_OPTIONS, LOGIN_BY_PROJECT, LOGIN_BY_PROJECT_DEFAULT_OPTIONS, LOGOUT } from './modules/Auth';
import { GET_ALL_FLOORS, GET_ALL_FLOORS_OPTIONS } from './modules/Floor';
import { UPDATE_DIMENSIONS_VISITS_MUTATION, UPDATE_DIMENSION_VISITS_OPTIONS } from './modules/Dimension';
import {
  GET_CLIENT,
  CREATE_CLIENT,
  LOGIN_VENDOR_AS_CLIENT,
  SET_CLIENT_INTEREST,
  REMOVE_CLIENT_INTEREST,
  GET_CLIENT_DEFAULT_OPTIONS,
  CREATE_CLIENT_DEFAULT_OPTIONS,
  LOGIN_VENDOR_AS_CLIENT_DEFAULT_OPTIONS,
  SET_CLIENT_INTEREST_DEFAULT_OPTIONS,
  REMOVE_INTEREST_DEFAULT_OPTIONS,
} from './modules/Client';

import { GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_DEFAULT_OPTIONS, GET_PROJECT_TOOL_STATUS, GET_PROJECT_TOOL_STATUS_OPTIONS } from './modules/Product';

const Client = {
  GET_ALL_APPARTMENTS,
  GET_APPARTMENT,
  GET_PROJECT_INFO,
  GET_APPARTMENTS_FILTERED,
  UPDATE_APPARTMENT_VISITS_MUTATION,
  GET_CLIENT,
  GET_USER_INFO,
  GET_QUOTE,
  GET_QUOTE_BY_CLIENT,
  SEND_QUOTE_MUTATION,
  GET_DRAFT_QUOTE,
  GET_DRAFT_QUOTE_BY_CLIENT,
  SEND_PLAN,
  SEND_DRAW_PLAN,
  CREATE_QUOTE_MUTATION,
  UPDATE_QUOTE_MUTATION,
  CREATE_DRAFT_QUOTE_MUTATION,
  UPDATE_DRAFT_QUOTE_MUTATION,
  DELETE_DRAFT_QUOTE_MUTATION,
  UPDATE_MODEL_VISITS_MUTATION,
  UPDATE_TYPOLOGY_VISITS_MUTATION,
  UPDATE_DIMENSIONS_VISITS_MUTATION,
  UPDATE_PLAN_VISITS_MUTATION,
  CONTACT_MUTATION,
  ACTIVATE_MYHOME_MUTATION,
  GET_ALL_USERS,
  GET_USER_BY_CLIENT_ID,
  GET_ALL_VENDORS_PROFILE,
  GET_ALL_POLICIES,
  GET_FEATURES,
  REFRESH_TOKEN,
  LOGIN_BY_PROJECT,
  LOGOUT,
  GET_ALL_FLOORS,
  LOGIN_VENDOR_AS_CLIENT,
  SET_CLIENT_INTEREST,
  REMOVE_CLIENT_INTEREST,
  CREATE_CLIENT,
  UPDATE_USER,
  PRINT_PDF_FROM_HTML,
  PRINT_PDF_FROM_HTML_DEFAULT_OPTIONS,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  GET_APPARTMENTS_DEFAULT_OPTIONS,
  GET_APPARTMENT_DEFAULT_OPTIONS,
  GET_APPARTMENTS_FILTERED_DEFAULT_OPTIONS,
  UPDATE_APPARTMENT_VISITS_OPTIONS,
  GET_CLIENT_DEFAULT_OPTIONS,
  GET_USER_INFO_DEFAULT_OPTIONS,
  UPDATE_DIMENSION_VISITS_OPTIONS,
  GET_QUOTE_OPTIONS,
  GET_DRAFT_QUOTE_OPTIONS,
  SEND_PLAN_OPTIONS,
  SEND_DRAW_PLAN_OPTIONS,
  GET_QUOTE_BY_CLIENT_OPTIONS,
  CREATE_QUOTE_OPTIONS,
  UPDATE_QUOTE_OPTIONS,
  GET_DRAFT_QUOTE_BY_CLIENT_OPTIONS,
  CREATE_DRAFT_QUOTE_OPTIONS,
  UPDATE_DRAFT_QUOTE_OPTIONS,
  DELETE_DRAFT_QUOTE_OPTIONS,
  UPDATE_MODEL_VISITS_OPTIONS,
  UPDATE_PLAN_VISITS_OPTIONS,
  UPDATE_TYPOLOGY_VISITS_OPTIONS,
  CONTACT_OPTIONS,
  ACTIVATE_MYHOME_OPTIONS,
  GET_USER_BY_CLIENT_ID_OPTIONS,
  GET_ALL_POLICIES_DEFAULT_OPTIONS,
  GET_FEATURES_OPTIONS,
  REFRESH_TOKEN_DEFAULT_OPTIONS,
  LOGIN_BY_PROJECT_DEFAULT_OPTIONS,
  GET_ALL_FLOORS_OPTIONS,
  GET_ALL_VENDORS_PROFILE_OPTIONS,
  LOGIN_VENDOR_AS_CLIENT_DEFAULT_OPTIONS,
  SET_CLIENT_INTEREST_DEFAULT_OPTIONS,
  REMOVE_INTEREST_DEFAULT_OPTIONS,
  CREATE_CLIENT_DEFAULT_OPTIONS,
  UPDATE_USER_DEFAULT_OPTIONS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_DEFAULT_OPTIONS,
  GET_PROJECT_TOOL_STATUS,
  GET_PROJECT_TOOL_STATUS_OPTIONS,
};

export default Client;
