import Cookies from "js-cookie";
import psl from "psl";

const clientKey = 'clientId';
const myHomeKey = 'myHome';
const clientDataKey = 'clientData';
const languageKey = 'userLanguage';

let domain = "localhost";
if (window.location.hostname !== "localhost") {
  const pslUrl = psl.parse(window.location.hostname);
  domain = pslUrl.domain;
}

const cookieOptionsSet = {
    expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
    domain: domain,
};

const cookieOptionsRemove = {
    domain: domain,
};


export function set(key, value) {
    Cookies.set(key, value, cookieOptionsSet);
}

export function get(key) {
    return Cookies.get(key);
}

export function remove(key) {
    return Cookies.remove(key, cookieOptionsRemove);
}

export function getClientID() {
    return Cookies.get(clientKey);
}

export function setClientId(clientId) {
    return Cookies.set(clientKey, clientId, cookieOptionsSet);
}

export function removeClientId() {
    Cookies.remove(clientKey, cookieOptionsRemove);
}

export function setMyHome(myHome) {
    Cookies.set(myHomeKey, myHome, cookieOptionsSet);
}

export function getMyHome() {
    return Cookies.get(myHomeKey);
}

export function removeMyHome() {
    Cookies.remove(myHomeKey, cookieOptionsRemove);
}

export function getClientData() {
    return Cookies.get(clientDataKey) ? JSON.parse(Cookies.get(clientDataKey)) : null;
}

export function setClientData(clientData) {
    let clientDataObj = {firstName: clientData.firstName, lastName: clientData.lastName, email: clientData.email, notificationLanguage: clientData.notificationLanguage};
  
    Cookies.set(clientDataKey, JSON.stringify(clientDataObj), cookieOptionsSet);
}

export function removeClientData() {
    Cookies.remove(clientDataKey, cookieOptionsRemove);
}

export function getUserLanguage() {
    const languageFromCookies = Cookies.get(languageKey);
    return languageFromCookies;
}

export function setUserLanguage(language) {
    return Cookies.set(languageKey, language, cookieOptionsSet);
}

export function vendorLogout() {
    removeClientData();
}

export function clientLogout() {
    removeClientId();
    removeMyHome();
    removeClientData();
}

const CookiesUtils = {
    set,
    get,
    remove,
    getClientID,
    setClientId,
    removeClientId,
    getMyHome,
    setMyHome,
    removeMyHome,
    getClientData,
    setClientData,
    removeClientData,
    getUserLanguage,
    setUserLanguage,
    vendorLogout,
    clientLogout
};
export default CookiesUtils;