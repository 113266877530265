import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const API = (store) => {
  const errorLink = onError(({ networkError }) => {
    if (networkError?.result?.errors[0]?.extensions?.code === 'UNAUTHENTICATED' || networkError?.statusCode === 401) {
      // CookiesUtils.vendorLogout();
      // store.setLoginVendor(false);
      // store.setVendorEmail(null);
      // if (isNeurosales()) {
      //   window.location.href = '/floorplanning/login';
      // } else if(window.location.pathname.indexOf('/login') === -1) {
      //   window.location.href = '/login';
      // }
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      uri: process.env.REACT_APP_APOLLO_URI,
      credentials: 'include',
    }),
  ]);

  const authLink = new ApolloLink((operation, forward) => {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

    if (operation.variables && !operation.getContext().hasUpload) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    credentials: 'include',
  });
};

export default API;
