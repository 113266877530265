import { gql } from '@apollo/client';
import { CUSTOM_QUOTE_DATA_TO_RETRIEVE} from './Quote'

 const DRAFT_QUOTE_DATA_TO_RETRIEVE = `
  id  
  client
  vendor
  url
  ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
  createdOn
  updatedOn
  customQuoteHelperInfo {
    paymentsHelperInfo{
      name
      index
      initialType
    }
    promosHelperInfo{
      name
      index
    }
  }
`;

export const GET_DRAFT_QUOTE_BY_CLIENT = gql`
  query getDraftQuoteByClient($project_id :ID!, $client: ID!) {
      getDraftQuoteByClient(project_id: $project_id, client: $client) {
      ${DRAFT_QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_DRAFT_QUOTE = gql`
  query getDraftQuote($project_id :ID!, $id: ID!) {
    getDraftQuote(project_id: $project_id, id: $id) {
      ${DRAFT_QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const CREATE_DRAFT_QUOTE_MUTATION = gql`
mutation createDraftQuotes($project_id :ID!, $draftquoteInput: DraftQuoteInput!) {
  createDraftQuotes(project_id: $project_id, input: $draftquoteInput) {
        ${DRAFT_QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;

export const UPDATE_DRAFT_QUOTE_MUTATION = gql`
mutation updateDraftQuotes($project_id :ID!, $draftquoteId: ID!, $draftquoteInput: DraftQuoteInput!) {
  updateDraftQuotes(project_id: $project_id, id: $draftquoteId, input: $draftquoteInput) {
        ${DRAFT_QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;

export const DELETE_DRAFT_QUOTE_MUTATION = gql`
mutation deleteDraftQuote($project_id :ID!, $draftquoteId: ID!) {
  deleteDraftQuote(project_id :$project_id, id: $draftquoteId) 
}`;

export const GET_DRAFT_QUOTE_BY_CLIENT_OPTIONS = (project_id, client, skip=false) => {
  return {
    variables: {
      project_id,
      client,
    },
    fetchPolicy: 'network-only',
    skip:skip
  };
};

export const GET_DRAFT_QUOTE_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};

export const CREATE_DRAFT_QUOTE_OPTIONS = (project_id, draftquoteInput) => {
  return {
    variables: {
      project_id,
      draftquoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};

export const UPDATE_DRAFT_QUOTE_OPTIONS = (project_id, draftquoteId, draftquoteInput) => {
  return {
    variables: {
      project_id,
      draftquoteId,
      draftquoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};

export const DELETE_DRAFT_QUOTE_OPTIONS = (project_id, draftquoteId) => {
  return {
    variables: {
      project_id,
      draftquoteId
    },
    fetchPolicy: 'no-cache',
  };
};