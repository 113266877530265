import { observable, action, decorate, computed } from 'mobx';
import { decodeUrlString } from '../utils/utils';
import { lazy } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { persist } from 'mobx-persist';
import { WEB_MODE, COMPONENTS_DELETED_IN_NEW_FLOW_MODE } from '../constants/flow';
import { iso2toiso3 } from '../utils/utils';
import { toJS } from 'mobx';
import { cloneDeep } from 'lodash';
import filterToolsAndProducts from '../utils/FilterToolsAndProducts';

const AppartmentFilter = lazy(() => import('../components/pages/AppartmentFilter'));
const Planimetry = lazy(() => import('../components/pages/Planimetry'));
const Building = lazy(() => import('../components/pages/Building'));
const Floor = lazy(() => import('../components/pages/Floor'));
const ComponentSwitcherDas = lazy(() => import('../components/pages/Das/ComponentSwitcherDas'));
const FloorDas = lazy(() => import('../components/pages/Das/FloorDas'));
const PlanimetryDas = lazy(() => import('../components/pages/Das/PlanimetryDas'));
const ComponentSwitcher = lazy(() => import('../components/ComponentSwitcher'));
const Summary = lazy(() => import('../components/pages/Summary'));

const componentsMap = {
  AppartmentFilter: AppartmentFilter,
  Planimetry: Planimetry,
  Building: Building,
  Floor: Floor,
  ComponentSwitcherDas: ComponentSwitcherDas,
  FloorDas: FloorDas,
  PlanimetryDas: PlanimetryDas,
  ComponentSwitcher: ComponentSwitcher,
  Summary: Summary,
};
 
class Store {
  constructor() {
    this.context = {
      currentPage: 0,
      config: [],
      data: {
        models: [],
        typologies: {},
        dimensions: {},
        floorplans: {},
      },
      mode: WEB_MODE,

      summary: {},
      error: null,
      user: {
        interested_appartments: [],
        selected_appartment: null,
        id: null,
      },
    };
    this.projectTitle = '';
    this.projectId = '';
    this.projectName = '';
    this.projectDisplayName = '';
    this.projectLocale = null;
    this.projectCurrency = null;
    this.projectDefaultLang = '';
    this.hostKey = '';
    this.projectUrl = '';
    this.installment = {};
    this.linkNoteLegali = '';
    this.language = '';
    this.availableLanguages = [];
    this.flowWeb = [];
    this.flowDesk = [];
    this.quotation = {};
    this.activeFilters = [];
    this.customQuote = {};
    this.loginVendor = false;
    this.loginClient = false;
    this.componentMode = false;
    this.activePromos = {};
    this.backToNeurosales = () => {};
    this.vendorEmail = null;
    this.allAppartments = null;
    this.allPolicies = null;
    this.allFloors = null;
    this.withDAS = false;
    this.quotationCloseHandler = null;
    this.draftQuoteData = null;
    this.draftQuoteIntervalId = null;
    this.showRata = false;
    this.visible = true;
    this.showRegisterForm = false;
    this.showRegisterButton = false;
    this.languageChangeHandler = null;
    this.policyFlags = [];
    this.loggedUser = null;
    this.loggedToken = null;
    this.checkingJwt = false;
    this.forcingLogout = false;
    this.clientName = null;
    this.myhomeProDisabled = null;
    this.initializing = true;
    this.baseUrl = undefined;
    this.proposalTemplate = [];
    this.products = [];
    this.enabledTools = [];
  }

  setAssetsByObject(obj) {
    if (obj) {
      this.projectTitle = obj.pageTitles.floorPlanning;
      this.projectId = obj.id;
      this.projectName = obj.name;
      this.projectDisplayName = obj.displayName;
      this.hostKey = obj.hostKey;
      this.projectUrl = obj.projectUrl;
      this.installment = obj.floorPlanningConfig.installment;
      this.linkNoteLegali = obj.floorPlanningConfig.linkNoteLegali;
      //this.language = obj.floorPlanningConfig.language;
      this.flowWeb = obj.floorPlanningConfig.flowWeb;
      this.flowDesk = obj.floorPlanningConfig.flowDesk;
      this.quotation = obj.floorPlanningConfig.quotation;
      this.availableLanguages = obj.floorPlanningConfig.languages || ['en-GB', 'it-IT'];
      this.policyFlags = obj.policyFlags;
      this.myhomeProDisabled = obj.myhomeProDisabled;
      this.enabledTools = obj.enabledTools;
      this.baseUrl = obj.baseurl;
      this.proposalTemplate = obj.proposalTemplate;
      this.projectLocale = obj.locale;
      this.projectCurrency = obj.currency;
      this.projectDefaultLang = obj.defaultLang;
    }
  }

  getPolicyFlags() {
    const language = this.language.split('-')[0];
    const policyFlags = this.policyFlags && (this.policyFlags[iso2toiso3(language)] || this.policyFlags[Object.keys(this.policyFlags)[0]]);
    return policyFlags;
  }

  getFlow() {
    const pages = toJS(this.context.mode === WEB_MODE ? this.flowWeb : this.flowDesk)
      //FOR DEBUG NEW FLOW
      /* ?.filter((page) => !COMPONENTS_DELETED_IN_NEW_FLOW_MODE.includes(page.component)) */
      .map((page) => {
        page.reactComponent = componentsMap[page.component];
        if (page?.componentParams?.components) {
          page.componentParams.components.forEach((innerBox) => {
            innerBox.component = componentsMap[innerBox.component];
          });
        }
        return page;
      });
    return pages;
  }

  getPlanningComponentPropsFlow() {
    const componentProps = this.getFlow().find((page) => page.component === 'Planimetry');
    return componentProps;
  }

  setActiveFiltersByNav(nav) {
    this.activeFilters = {
      model: nav.match.params.model ? decodeUrlString(nav.match.params.model) : undefined,
      typology: nav.match.params.typology ? decodeUrlString(nav.match.params.typology) : undefined,
      dimension: nav.match.params.dimension ? decodeUrlString(nav.match.params.dimension) : undefined,
      das: nav.match.params.das ? decodeUrlString(nav.match.params.das) : undefined,
      side: nav.match.params.side ? decodeUrlString(nav.match.params.side) : undefined,
      formula: nav.match.params.formula ? decodeUrlString(nav.match.params.formula) : undefined,
      planimetry: nav.match.params.planimetry ? decodeUrlString(nav.match.params.planimetry) : undefined,
      appartmentDas: nav.match.params.appartmentDas ? decodeUrlString(nav.match.params.appartmentDas) : undefined,
      building: nav.match.params.building ? decodeUrlString(nav.match.params.building) : undefined,
      appartment: nav.match.params.appartment ? decodeUrlString(nav.match.params.appartment) : undefined,
    };
  }

  setActiveFilters(activeFilters) {
    this.activeFilters = activeFilters;
  }

  addActiveFilter(key, value) {
    this.activeFilters[key] = decodeUrlString(value);
  }

  removeActiveFilter(key) {
    this.activeFilters[key] = undefined;
  }

  setMode(mode) {
    this.context.mode = mode;
  }

  setCustomQuote(customQuote) {
    this.customQuote = customQuote;
  }

  setAllAppartments(allAppartments) {
    this.allAppartments = allAppartments;
  }

  setActivePromos(activePromos) {
    this.activePromos = activePromos;
  }

  setShowRata(showRata) {
    this.showRata = showRata;
  }

  setShowRegisterForm(showRegisterForm) {
    this.showRegisterForm = showRegisterForm;
  }

  setShowRegisterButton(showRegisterButton) {
    this.showRegisterButton = showRegisterButton;
  }

  setAllPolicies(allPolicies) {
    this.allPolicies = allPolicies;
  }

  setAllFloors(allFloors) {
    this.allFloors = allFloors;
  }

  setCurrentPage(currentPage) {
    this.context.currentPage = currentPage;
  }

  get currentPage() {
    return this.context.currentPage;
  }

  setVisible(visible) {
    this.visible = visible;
  }

  setInstallmentPercentage(percentage) {
    this.installment.percentage = percentage;
  }

  setVendorEmail(email) {
    this.vendorEmail = email;
  }

  setLoginVendor(loginVendor) {
    this.loginVendor = loginVendor;
  }

  setLoginClient(loginClient) {
    this.loginClient = loginClient;
  }

  setComponentMode(componentMode) {
    this.componentMode = componentMode;
  }

  setBackToNeurosales(backToNeurosales) {
    this.backToNeurosales = backToNeurosales;
  }

  setWithDAS(withDAS) {
    this.withDAS = withDAS;
  }

  setQuotationCloseHandler(inputQuotationCloseHandler) {
    this.quotationCloseHandler = inputQuotationCloseHandler;
  }
  setDraftQuoteData(draftQuoteData) {
    if (!draftQuoteData) {
      this.draftQuoteData = null;
      return;
    }

    let key;
    if (Object.keys(draftQuoteData).length > 1) return;

    if (Object.keys(draftQuoteData)[0].includes('create')) key = 'createDraftQuotes';
    else if (Object.keys(draftQuoteData)[0].includes('update')) key = 'updateDraftQuotes';
    else if (Object.keys(draftQuoteData)[0].includes('get')) key = 'getDraftQuoteByClient';

    this.draftQuoteData = { ...draftQuoteData[key] };
  }

  setDraftQuoteIntervalId(id) {
    this.draftQuoteIntervalId = id;
  }

  setLanguage(language) {
    this.language = language;
  }

  setLanguageChangeHandler(handler) {
    if (handler) {
      this.languageChangeHandler = handler;
    }
  }

  setAvailableLanguages(languages) {
    if (languages && languages.length > 0) {
      if (this.availableLanguages.length > 0) {
        this.availableLanguages.splice(0, this.availableLanguages.length);
      }
      this.availableLanguages = [...languages];
    }
  }

  setLoggedUser(val) {
    this.loggedUser = val;
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.setUser({
        id: val.id,
        name: val.firstName,
        surname: val.lastName,
        email: val.email,
        role: val.role,
      });
    }
  }

  setLoggedToken(val) {
    this.loggedToken = val;
  }

  setForcingLogout(val) {
    this.forcingLogout = val;
  }

  setCheckingJwt(val) {
    this.checkingJwt = val;
  }

  get reCommerceLink() {
    const reCommerceUrl = this.enabledTools.find((tool) => tool.name === 'ReCommerce')?.url;
    return reCommerceUrl ?? sessionStorage.getItem('newUrlLogo') ?? `${process.env.REACT_APP_WEB_PROTOCOL}${this.hostKey}`;
  }

  get mode() {
    return this.context.mode;
  }

  get vendorName() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) return this.loggedUser.firstName + ' ' + this.loggedUser.lastName;
    else if (this.loggedUser) return '';
    else return null;
  }

  get isNewFlow() {
    const foundComponentIndex = this.getFlow().findIndex((page) => COMPONENTS_DELETED_IN_NEW_FLOW_MODE.includes(page.component));
    return foundComponentIndex === -1 ? true : false;
  }

  setClientName(val) {
    this.clientName = val;
  }

  setInitializing(val) {
    this.initializing = val;
  }

  logOut() {
    this.loginClient = false;
    this.allAppartments = null;
    this.draftQuoteData = null;
    this.vendorEmail = null;
    this.loginVendor = false;
    this.clientName = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.checkingJwt = false;
    this.forcingLogout = false;
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.clearUser();
    }
  }

  get redirectUrl() {
    if (this.projectUrl && this.projectUrl !== '') return this.projectUrl;
    else return process.env.REACT_APP_WEB_PROTOCOL + this.hostKey;
  }

  setProducts(value, toolStatus) {
    if (toolStatus) {
      const products = [];
      value.forEach((tool) => {
        const status = toolStatus.filter((ts) => filterToolsAndProducts(ts, tool))[0];
        if (status) {
          const myTool = cloneDeep(tool);
          myTool.status = status.status;
          products.push(myTool);
        }
      });

      this.products = [...products];
    } else {
      this.products = [...value];
    }
  }
}

decorate(Store, {
  context: observable,
  AppartmentFilter: observable,
  Planimetry: observable,
  Building: observable,
  Floor: observable,
  Das: observable,
  AppartmentDas: observable,
  Summary: observable,
  ComponentSwitcher: observable,
  componentMode: observable,
  visible: observable,
  vendorEmail: observable,
  loginVendor: observable,
  loginClient: observable,
  projectTitle: observable,
  projectId: observable,
  projectName: observable,
  hostKey: observable,
  projectUrl: observable,
  projectDisplayName: observable,
  projectDefaultLang: observable,
  proposalTemplate: observable,
  installment: observable,
  linkNoteLegali: observable,
  availableLanguages: observable,
  flowWeb: observable,
  flowDesk: observable,
  quotation: observable,
  activeFilters: observable,
  customQuote: observable,
  activePromos: observable,
  allAppartments: [persist('object'), observable],
  allPolicies: observable,
  allFloors: observable,
  withDAS: observable,
  showRata: observable,
  showRegisterForm: observable,
  showRegisterButton: observable,
  policyFlags: observable,
  loggedUser: observable,
  loggedToken: observable,
  forcingLogout: observable,
  checkingJwt: observable,
  clientName: observable,
  language: observable,
  initializing: observable,
  myhomeProDisabled: observable,
  products: observable,
  enabledTools: observable,

  setMode: action.bound,
  setCurrentPage: action.bound,
  setActiveFiltersByNav: action.bound,
  setActiveFilters: action.bound,
  addActiveFilter: action.bound,
  removeActiveFilter: action.bound,
  setAssetsByObject: action.bound,
  setVisible: action.bound,
  setCustomQuote: action.bound,
  setActivePromos: action.bound,
  setAllAppartments: action.bound,
  setAllPolicies: action.bound,
  setAllFloors: action.bound,
  setWithDAS: action.bound,
  setInstallmentPercentage: action.bound,
  setComponentMode: action.bound,
  setQuotationCloseHandler: action.bound,
  setDraftQuoteData: action.bound,
  setDraftQuoteIntervalId: action.bound,
  setShowRata: action.bound,
  setShowRegisterForm: action.bound,
  setShowRegisterButton: action.bound,
  setLanguage: action.bound,
  setLanguageChangeHandler: action.bound,
  setAvailableLanguages: action.bound,
  setForcingLogout: action.bound,
  setCheckingJwt: action.bound,
  setClientName: action.bound,
  logOut: action.bound,
  setInitializing: action.bound,
  setProducts: action.bound,

  vendorName: computed,
  reCommerceLink: computed,
  redirectUrl: computed,
  isNewFlow: computed,
  mode: computed,
  currentPage: computed,
});

export default Store;
