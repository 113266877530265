/**
 * gets actual host
 * @todo remove this utility
 */

import { useStore } from '../store/storeUtils';
import CookiesUtils from '../utils/cookiesUtils';
import { useMutation } from '@apollo/client';
import Client from '../client/GraphQLEndpoints';

export const DetectUrl = () => {
  let hostname = window.location.hostname.indexOf('www.') >= 0 ? window.location.hostname : 'www.' + window.location.hostname;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');
  const store = useStore();

  const [logoutMutation] = useMutation(Client.LOGOUT);

  if (hostname.indexOf('localhost') >= 0 || hostname.indexOf('.ddns.net') >= 0 || hostname.indexOf('biz-tecma') >= 0 || hostname.indexOf('-demo.tecmasolutions.com') >= 0) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '' && localStorage['floorplanning_dev_hostname'] !== hostnameParam) {
      if (localStorage['floorplanning_dev_hostname']) {
        logoutMutation().catch((err) => console.error(err));

        CookiesUtils.vendorLogout();
        CookiesUtils.clientLogout();
        store.setLoginVendor(false);
        store.setVendorEmail(null);
        store.setLoginClient(false);
        store.setShowRegisterForm(false);
        store.setShowRegisterButton(false);
        store.setLoggedUser(null);
        store.setClientName(null);
      }
      localStorage.clear();
      delete localStorage['floorplanning_dev_hostname'];
      localStorage.setItem('floorplanning_dev_hostname', hostnameParam);
    }

    let floorplanning_dev_hostname = localStorage.getItem('floorplanning_dev_hostname');

    if (floorplanning_dev_hostname && floorplanning_dev_hostname.length > 0 && floorplanning_dev_hostname !== '') {
      return floorplanning_dev_hostname;
    }

    hostname = process.env.REACT_APP_HOST;
  }

  return hostname;
};

export const DetectUrlWithProtocol = () => {
  return process.env.REACT_APP_WEB_PROTOCOL + DetectUrl();
};

export default {
  DetectUrl,
  DetectUrlWithProtocol,
};
