import React from 'react';
import Loading from 'react-loader-spinner';
import PropTypes from 'prop-types';
const Loader = ({ type }) => {
  return (
    <div className="extender editor">
      <div className="padder">
        <Loading type={type} color="#e5ccb3" height={100} width={100} F />
      </div>
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.string.isRequired,
};
export default Loader;
