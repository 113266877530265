import { gql } from '@apollo/client';

const CONTACT_DATA = `
  firstName
  lastName
  email
  tel
  trattamento
  profilazione
  marketing
  note
  city
  status
`;

export const CONTACT_MUTATION = gql`
  mutation getInfoByFloorPlanning($project_id :ID!, $getInfoByFloorPlanningInput: GetInfoByFloorPlanningInput!) {
    getInfoByFloorPlanning(project_id: $project_id, input: $getInfoByFloorPlanningInput) {
          ${CONTACT_DATA}
          }
      }
`;

export const CONTACT_OPTIONS = (project_id, getInfoByFloorPlanningInput) => {
  return {
    variables: {
      project_id,
      getInfoByFloorPlanningInput
    },
    fetchPolicy: 'no-cache',
  };
};