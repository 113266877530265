import { gql } from '@apollo/client';

export const CLIENT_DATA_TO_RETRIEVE = `
    id
    firstName
    lastName
    email
    user
    notificationLanguage
    interested_appartments {
      appartment

    }
    selected_appartments {
      appartment
      status
    }
`;

export const INTEREST_DATA_TO_RETRIEVE = `
    id
    email
    interested_appartments {
      appartment
    }
    selected_appartments {
      appartment
      status
    }
    nAppuntamenti
    nPreventiviRichiesti
`;

export const GET_CLIENT = gql`
    query getClient($project_id :ID!, $id: ID!) {
        getClient(project_id: $project_id, id: $id) {
        ${CLIENT_DATA_TO_RETRIEVE}
        }
    }
`;

export const CREATE_CLIENT = gql`
  mutation createClients($project_id: ID!, $input: ClientInput!) {
    createClients(project_id: $project_id, input: $input) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

export const LOGIN_VENDOR_AS_CLIENT = gql`
    query loginVendorAsClient($project_id: ID!, $email: String!, $vendorId: ID!) {
        loginVendorAsClient(project_id: $project_id, email: $email, vendorId: $vendorId) {
        ${CLIENT_DATA_TO_RETRIEVE}
    }
    }
`;

export const SET_CLIENT_INTEREST = gql`
    mutation setClientInterest($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $vendorId: ID!) {
        setClientInterest(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, vendorId: $vendorId) {
        ${INTEREST_DATA_TO_RETRIEVE}
    }
    }
`;

export const REMOVE_CLIENT_INTEREST = gql`
    mutation removeClientInterest($project_id: ID!, $clientId: ID!, $appartmentId: ID!) {
        removeClientInterest(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId) {
        ${INTEREST_DATA_TO_RETRIEVE}
    }
    }
`;

export const GET_CLIENT_DEFAULT_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};

export const CREATE_CLIENT_DEFAULT_OPTIONS = (pId, input) => {
    return {
      variables: {
        project_id: pId,
        input: input,
      },
    };
  };
  
export const LOGIN_VENDOR_AS_CLIENT_DEFAULT_OPTIONS = (projectId, email, vendorId, onCompleteHandler=null) => {
    return {
        variables: {
            project_id: projectId,
            email: email,
            vendorId: vendorId,
        },
        onCompleted: onCompleteHandler ? onCompleteHandler : () => {}
    };
};

export const SET_CLIENT_INTEREST_DEFAULT_OPTIONS = (projectId, clientId, appartmentId, vendorId) => {
    return {
        variables: {
            project_id: projectId,
            clientId: clientId,
            appartmentId: appartmentId,
            vendorId: vendorId,
        },
    };
};

export const REMOVE_INTEREST_DEFAULT_OPTIONS = (projectId, clientId, appartmentId) => {
    return {
        variables: {
            project_id: projectId,
            clientId: clientId,
            appartmentId: appartmentId,
        },
    };
};



