import { gql } from '@apollo/client';

const POLICY_DATA_TO_RETRIEVE = `
  id
  name
  number
  text
`;

export const GET_ALL_POLICIES = gql`
  query getAllPolicies($project_id: ID!) {
    getAllPolicies(project_id: $project_id) {
      ${POLICY_DATA_TO_RETRIEVE}
    }            
  }
`;

export const GET_ALL_POLICIES_DEFAULT_OPTIONS = (project_id, skip=false) => {
  return {
    variables: {
      project_id
    },
    fetchPolicy: 'no-cache',
    skip:skip
  };
};