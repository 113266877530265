import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel';
export const useTracking = (
) => {
    const {listen} = useHistory();
    useEffect(()=>{
        const unlisten= listen((location) => {
          ReactPixel.pageView();
        })
        return unlisten;
    },[listen]) 
    
}