import React, { useEffect, useState } from 'react';
import { Snackbar, Button } from '@material-ui/core';
import * as serviceWorker from '../serviceWorker';
import Loader from '../components/common/Loader';
import { useTranslation } from '@tecma/i18n';

const ServiceWorkerWrapper = ({ baseUrl = '/' }) => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [showReloading, setShowReloading] = React.useState(false);

  const { t } = useTranslation();

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate }, baseUrl);
  }, []);

  const reloadPage = () => {
    delete localStorage['store'];
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      waitingWorker.postMessage('SKIP_WAITING');
      setShowReloading(true);
    }
  };

  return (
    <Snackbar
      open={showReload}
      message={
        !showReloading ? (
          t('serviceworker.newVersion.message', { defaultValue: 'Una nuova versione è disponibile!' })
        ) : (
          <>
            <div style={{ height: '120px' }}></div>
          </>
        )
      }
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ zIndex: '9999999999999999' }}
      action={
        // <ClearCache>
        //   {clearCacheBtn}
        //   </ClearCache>
        showReloading ? (
          <div style={{ position: 'absolute', top: 'calc(50% - 50px)', left: 'calc(50% - 50px)' }}>
            <Loader type='TailSpin' />
          </div>
        ) : (
          <Button color='inherit' size='small' onClick={reloadPage}>
            Aggiorna
          </Button>
        )
      }
    />
  );
};

export default ServiceWorkerWrapper;
