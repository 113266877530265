import TagManager,{ ReactPixel} from "@tecma/analytics";

export const inizializeAnalytics = ({ gtmId, fbPixelId }) => {
    if (gtmId) {
        TagManager.initialize({
            gtmId: gtmId
        })
    }
    
    if (fbPixelId) {
        ReactPixel.init(
            fbPixelId,
            {},
            { debug: true, autoConfig: false }
        );
        ReactPixel.pageView();
    }
}