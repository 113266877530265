import { gql } from '@apollo/client';

export const STANDARD_QUOTE_DATA_TO_RETRIEVE = `
standardQuote {
  features {
    id
    available
    code
    floor
    length
    meters
    name
    notes
    price
    status
    type
    visible
    width
  }
  boxes {
    box
    price
    price_iva
    iva
    meters
    floor
  }
  basements {
    basement
    price
    price_iva
    iva
    meters
    floor
  }
  parkingSpace_bykes {
    parkingSpace_byke
    price
    price_iva
    iva
    meters
    floor
  }
  parkingSpace_cars {
    parkingSpace_car
    price
    price_iva
    iva
    meters
    floor
  }
  promos {
    promo
    percentage
    name
    info
    price
    endDate
    notes
    class
    threshold
  }
  payments {
    name
    endDate
    price
    price_iva
    iva
    percentage
    daysForPayment
    notes
    type
  }
  expenses {
    name
    price
    price_iva
    iva
    percentage
    emptyLabel
    type
  }
  iva {
    name
    percentage
  }
  priceVat
  promoPrice
  promoPriceVat
  fixedRate {
    percentage
    value
  }
  variableRate {
    percentage
    value
  }
  rate
  expiredDate
  daysofexpired
  taxMortgage
  timeMortgage
  vatValue
  showVat
}
`;

export const CUSTOM_QUOTE_DATA_TO_RETRIEVE = `
customQuote 
  {
    features {
      id
      available
      code
      floor
      length
      meters
      name
      notes
      price
      status
      type
      visible
      width
    }
    quadrantCode
    capitolato {
      name
      price
    }
    boxes {
      box
      price
      price_iva
      iva
      meters
      floor
    }
    basements {
      basement
      price
      price_iva
      iva
      meters
      floor
    }
    parkingSpace_bykes {
      parkingSpace_byke
      price
      price_iva
      iva
      meters
      floor
    }
    parkingSpace_cars {
      parkingSpace_car
      price
      price_iva
      iva
      meters
      floor
    }
    promos {
      promo
      percentage
      name
      info
      class
      price
      endDate
      notes
      threshold
    }
    payments {
      name
      endDate
      price
      price_iva
      iva
      percentage
      daysForPayment
      notes
      type
    }
    expenses {
      name
      price
      price_iva
      iva
      percentage
      emptyLabel
      type
    }
    priceVat
    promoPrice
    promoPriceVat
    fixedRate {
      percentage
      value
    }
    variableRate {
      percentage
      value
    }
    rate
    freeNotes
    buildingName
    expiredDate
    daysofexpired
    taxMortgage
    timeMortgage
    vatValue
    showVat
  }
`;

const QUOTE_DATA_TO_RETRIEVE = `
  id  
  client {
    id
  }
  vendor
  appartment {
    name
    id
    floor
    price
    discounted_price
    available
    active
    code
    enabled_web
    enabled_desk
    status
    extraInfo
    superfici
    selectedBy
    {
      id
      client {
        id
      }
    }
    plan
    {
    name
    id
    extraInfo
    model {
        id
        name
        visits
    }
    typology {
        id
        name
        visits
        rooms
        formulas {
          id
          increase
          name: label
          shortDescription
          description: longDescription
        }
    }
    dimension {
      name
    }
    SuperficieGiardino
    SuperficieTotale
    SuperficieTerrazzo
    SuperficieAppartamento
    visits
    image
    }
    sides {
      id
      name
      building {
        id
        name
        floors
      }
    }
    ${STANDARD_QUOTE_DATA_TO_RETRIEVE}
  }
  reserved
  errorMessage
  ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
`;

export const GET_QUOTE_BY_CLIENT = gql`
  query getQuotesByClient($project_id :ID!, $client: ID!) {
      getQuotesByClient(project_id: $project_id, client: $client) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const GET_QUOTE = gql`
  query getQuote($project_id :ID!, $id: ID!) {
    getQuote(project_id: $project_id, id: $id) {
      ${QUOTE_DATA_TO_RETRIEVE}
      }
  }
`;

export const CREATE_QUOTE_MUTATION = gql`
mutation createQuotes($project_id :ID!, $quoteInput: QuoteInput!) {
  createQuotes(project_id: $project_id, input: $quoteInput) {
        ${QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;

export const UPDATE_QUOTE_MUTATION = gql`
mutation updateQuotes($project_id :ID!, $quoteId: ID!, $quoteInput: QuoteInput!) {
  updateQuotes(project_id: $project_id, id: $quoteId, input: $quoteInput) {
        ${QUOTE_DATA_TO_RETRIEVE}
        }
    }
`;

export const SEND_QUOTE_MUTATION = gql`
  mutation sendQuote($project_id: ID!, $quoteInput: QuoteInput!, $sendMail: Boolean) {
    sendQuote(project_id: $project_id, input: $quoteInput, sendMail: $sendMail)
  }
`;

export const GET_QUOTE_BY_CLIENT_OPTIONS = (project_id, client) => {
  return {
    variables: {
      project_id,
      client,
    },
    fetchPolicy: 'network-only',
  };
};

export const GET_QUOTE_OPTIONS = (project_id, id) => {
  return {
    variables: {
      project_id,
      id,
    },
    fetchPolicy: 'network-only',
  };
};

export const CREATE_QUOTE_OPTIONS = (project_id, quoteInput, sendMail) => {
  return {
    variables: {
      project_id,
      quoteInput,
      sendMail,
    },
    fetchPolicy: 'no-cache',
  };
};

export const UPDATE_QUOTE_OPTIONS = (project_id, quoteId, quoteInput) => {
  return {
    variables: {
      project_id,
      quoteId,
      quoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};
