export const modifyTitle = (title) => {
  document.title = title;
};

export const modifyDescription = (description) => {
  var meta = document.getElementsByTagName('meta');
  for (var i = 0; i < meta.length; i++) {
    if (meta[i].name.toLowerCase() === 'description') {
      meta[i].content = description;
    }
  }
};

export const loadCss = (projectAssetFolder) => {
  if (process.env.REACT_APP_CSS_BASEURL) {
    loadHTMLElement(`/${process.env.REACT_APP_CSS_BASEURL}/${encodeURI(projectAssetFolder)}/tecma-bp-style.css`, 'css');
  } else {
    loadHTMLElement(`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(projectAssetFolder)}/floorplanning/css/tecma-bp-style.css`, 'css');
  }
};

export const loadFavicon = (projectAssetFolder) => {
  loadHTMLElement(`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(projectAssetFolder)}/global/img/favicon.ico`, 'icon');
  loadHTMLElement(`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(projectAssetFolder)}/global/img/logo192.png`, 'apple-touch-icon');
};

export const generateManifest = (config) => {
  if (config) {
    try {
      const manifestData = {
        name: config.name,
        short_name: config.shortName,
        icons: config.icons,
        start_url: window.location.protocol + '//' + window.location.hostname + '/',
        orientation: config.orientation,
        display: config.display,
        theme_color: config.themeColor,
        background_color: config.backgroundColor,
      };

      const stringManifest = JSON.stringify(manifestData);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#application-manifest').setAttribute('href', manifestURL);
    } catch (e) {
      console.log(e);
    }
  }
};

function loadHTMLElement(filename, filetype) {
  let fileref;
  if (filetype === 'js') {
    fileref = document.createElement('script');
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', filename);
  } else if (filetype === 'css') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    fileref.setAttribute('href', filename);
    fileref.setAttribute('id', 'css-floorplanning'); // necessario ad identificare l'elemento da smontare nel caso di Floorplanning importato
  } else if (filetype.includes('icon')) {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', filetype);
    fileref.setAttribute('href', filename);
  } else if (filetype === 'manifest') {
    fileref = document.createElement('link');
    fileref.setAttribute('rel', 'manifest');
    fileref.setAttribute('href', filename);
  }
  if (typeof fileref !== 'undefined') {
    document.getElementsByTagName('head')[0].appendChild(fileref);
  }
}
