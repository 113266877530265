import { gql } from '@apollo/client';

const PROJECT_DATA_TO_RETRIEVE = `
    id
    name
    logo
    vendorLogo
    assetLogin
    hostKey
    projectUrl
    assetKey
    feVendorKey
    pdf
    assetsUrl
    displayName
    pageTitles {
        floorPlanning
    }
    payoff
    floorPlanningConfig
    manifestConfig
    gtmId
    fbPixelId
    policyFlags
    myhomeProDisabled
    baseurl
    enabledTools
    locale
    currency
    defaultLang
    proposalTemplate
`;

export const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!, $url:String) {
    getProjectInfoByHost (host :$host, url:$url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

export const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};
