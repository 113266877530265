import { gql } from '@apollo/client';

export const PRINT_PDF_FROM_HTML = gql`
  mutation printPdfFromHtml($project_id: ID!, $pdfHtml: [String]!) {
    printPdfFromHtml(project_id: $project_id, pdfHtml: $pdfHtml)
  }
`;

export const PRINT_PDF_FROM_HTML_DEFAULT_OPTIONS = (project_id, pdfHtml) => {
  return {
    variables: {
      project_id,
      pdfHtml,
    },
  };
};
