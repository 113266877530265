import React from 'react';
import ReactDOM from 'react-dom';
import './SCSS/style.scss';
import './App.css';
import App from './App';
import Store from './store/store.jsx';
import { StoreProvider } from './store/storeUtils.jsx';
import { ApolloProvider } from '@apollo/client';
import API from './client/GraphqlClient';
import { create } from 'mobx-persist';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '@tecma/ds';
import translation from './i18n/en-GB/translation.json';
import { initI18n } from '@tecma/i18n';

const hydrate = create({});

const store = new Store();

initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: 'floorplanning',
  apiBase: process.env.REACT_APP_API_URI || '',
  expirationTime: 1000 * 60, // 1 minuto,
  cache: 'no-cache',
}).then(() =>
  hydrate('store', store).then(() => {
    ReactDOM.render(
      <StoreProvider value={store}>
        <ApolloProvider client={API(store)}>
          <ErrorBoundary
            FallbackComponent={() => {
              return (
                <ErrorPage
                  logo={store.projectDisplayName ? `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${encodeURI(store.projectDisplayName)}/global/img/primary-logo.svg` : undefined}
                  buttonOnClick={() => {
                    window.location.href = store.baseUrl;
                  }}
                />
              );
            }}
          >
            <div id='floorplanning-app'>
              <App />
            </div>
          </ErrorBoundary>
        </ApolloProvider>
      </StoreProvider>,
      document.getElementById('root'),
    );
  }),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
