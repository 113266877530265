import React from 'react';
import { observer } from 'mobx-react';
import { createContext } from 'react';
import { useStore } from '../store/storeUtils';

// Context per la gestione di currency dinamiche
export const CurrencyContext = createContext();

const CurrencyProvider = observer(({ children }) => {
  const store = useStore();

  return (
    <CurrencyContext.Provider
      value={{
        locale: store.language || store.projectDefaultLang || 'it-IT',
        currency: store.projectCurrency || 'EUR',
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
});
export default CurrencyProvider;
